import gql from "graphql-tag";

export type TechRequestFormPropType = {
    ID: number;
    ProductType: string;
    UniqueReference: string;
    LastUpdated: string;
    Enquiry: string;
    ProductValue: string;
    AcousticPerformance: string;
    SubmittedByUserID: number;
    TechRequestFormStatusID: number;
    CertifyingBody: {
      Value?: {
        Description: string;
      }
    }
    SubmittedByUser: {
      Value?: {
        ID: number;
        Firstname: string;
        Surname: string;
      }
    }
    LastUpdatedByUser: {
      Value?: {
        ID: number;
        Firstname: string;
        Surname: string;
      }
    }
    FireRating: {
      Value?: {
        Description: string;
      }
    }
    ProductLine: {
      Value?: {
        Name: string;
      }
    }
    Status: {
      Value?: {
          Description: string;
      }
    }
    TechRequestFormMessages: {
      ID: number;
      UserID: number;
      User: {
        Value?: {
          Firstname: string;
          Surname: string;
          Photo: string | null;
        }
      }
      Message: string;
      DatetimeStamp: string;
      TechRequestFormMessageAttachments: {
        ID: number;
        FileUrl: string;
      }[]
    }[]
    TechRequestFormMessageAttachment: {
      ID: number;
      TechRequestFormMessageID: number;
      FileUrl: string;
    }[]
    
}

export type TechRequestFormsQueryType = {
    TechRequestForms: {
        totalCount: number;
        items: TechRequestFormPropType[]
    }
}

export const TechRequestFormsGridQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $order: [TechRequestFormSortInput!],
    $where: TechRequestFormFilterInput,
    $organisationID: Int!
  ) {
    grid: TechRequestForms(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where,
      OrganisationID: $organisationID
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      items {
            ID
            ProductType
            UniqueReference
            LastUpdated
            SubmittedByUser {
              Value {
                ID
                Firstname
                Surname
              }
            }
            Status {
                Value {
                    Description
                }
            }
        }
      
    }
  }
`;

export const TechRequestFormScreenQuery = gql`
query(
    $id: Int!
  ) {
    screen: TechRequestForm(
      iD: $id,
    ) {
      ID
      ProductType
      UniqueReference
      LastUpdated
      Enquiry
      ProductType
      ProductValue
      AcousticPerformance
      SubmittedByUserID
      TechRequestFormStatusID
      CertifyingBody {
        Value {
          Description
        }
      }
      SubmittedByUser {
        Value {
          Firstname
          Surname
        }
      }
      LastUpdatedByUser {
        Value {
          ID
          Firstname
          Surname
        }
      }
      FireRating {
        Value {
          Description
        }
      }
      ProductLine {
        Value {
          Name
        }
      }
      Status {
          Value {
              Description
          }
      }
      TechRequestFormMessages {
        ID
        UserID
        User {
          Value {
            Firstname
            Surname
            Photo
          }
        }
        Message
        DatetimeStamp
        TechRequestFormMessageAttachments {
          ID
          FileUrl
        }
      }
      TechRequestFormMessageAttachment {
        ID
        TechRequestFormMessageID
        FileUrl
      }
    }
  }
`;
