import { useLazyQuery } from "@apollo/client"
import { BasketPropType, BasketScreenQuery, BasketItemsGridQueryType, BasketItemsGridQuery, BasketItemPropType } from "@services/query/BasketQuery"
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import cookie from "@whitecobalt/tungsten/esm/common/utils/cookie"
import Toaster from "@whitecobalt/tungsten/esm/components/Toaster"
import { SessionActionType, useSession } from "@whitecobalt/tungsten/Session"
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import branding from "@config/branding"

interface BasketInfoProps {
    id: number;
    sessionID?: number;
    orderID?: number;
    quoteID?: number;
}
interface BasketStates {
    loading: boolean;
    basketInfo: null | BasketInfoProps;
    anonymousBasketInfo: null | BasketInfoProps;
    hasOverlapBasket: boolean;
}

export const useCreateBasket = () => {
    const [{initial, token}] = useSession()
    const statesprovider = useState<BasketStates>({
        loading: true,
        basketInfo: null,
        anonymousBasketInfo: null,
        hasOverlapBasket: false
    })
    const history = useHistory()
    const request = useAuthAPI('/api/Basket')

    const [states, setStates] = statesprovider

    useEffect(() => {
        if(!initial) {
            const cacheBasket = cookie.get('anonymousBasket')
            const parseCacheBasket = cacheBasket && JSON.parse(cacheBasket)
            if(!token && cacheBasket) {
                
                setStates(prev => ({
                    ...prev,
                    loading: false,
                    basketInfo: {
                        id: parseCacheBasket?.id,
                        sessionID: parseCacheBasket?.sessionID
                    },
                    hasOverlapBasket: false
                }))
            } else {
                request.call().then((response) => {
                    if(response.data.success) {
                        const basketInfo = {
                            id: response.data.id,
                            sessionID: response.data.sessionID
                        }
                        
                        if(token && cacheBasket && parseCacheBasket?.hasItems) {
                            
                            setStates(prev => ({
                                ...prev,
                                loading: false,
                                basketInfo,
                                anonymousBasketInfo: parseCacheBasket,
                                hasOverlapBasket: true
                            }))
                        } else {

                            if(response.data.sessionID) {
                                cookie.set('anonymousBasket', JSON.stringify({...basketInfo, hasItems: false}), {
                                    expireday: 30,
                                    domain: branding.token.domain
                                })
                            }

                            // if(token && cacheBasket && !parseCacheBasket?.hasItems) {
                            //     cookie.unset('anonymousBasket', {
                            //         domain: branding.token.domain
                            //     })
                            // }
                            setStates(prev => ({
                                ...prev,
                                loading: false,
                                basketInfo,
                                hasOverlapBasket: false
                            }))
                        }
                    }
                }).catch((error) => {
                    setStates(prev => ({
                        ...prev,
                        loading: false,
                    }))
                    Toaster.error(error?.response?.data?.message || branding.messages.error)
                })
            }
            
        }
    }, [initial, !token])

    useEffect(() => {
        if(states.basketInfo?.id === 0) {
            request.call().then((response) => {
                if(response.data.success) {
                    const basketInfo = {
                        id: response.data.id,
                        sessionID: response.data.sessionID
                    }
                    setStates(prev => ({
                        ...prev,
                        basketInfo,
                        hasOverlapBasket: false
                    }))
                }
            })
            .catch((error) => {
                Toaster.error(error?.response?.data?.message || branding.messages.error)
            })
        }
    }, [history.location.pathname])

    useEffect(() => {
        const handle = function() {
            if (document.visibilityState === 'visible') {
                const cacheBasket = cookie.get('anonymousBasket')
                const parseCacheBasket = cacheBasket && JSON.parse(cacheBasket)
                if(!!token && !(cacheBasket && parseCacheBasket?.hasItems)) {
                    request.call().then((response) => {
                        if(response.data.success) {
                            const basketInfo = {
                                id: response.data.id,
                                sessionID: response.data.sessionID
                            }
                            setStates(prev => ({
                                ...prev,
                                basketInfo,
                                hasOverlapBasket: false
                            }))
                        }
                    })
                    .catch((error) => {
                        Toaster.error(error?.response?.data?.message || branding.messages.error)
                    })
                }
            } else {
                // console.log('lost focus');
            }
        }
        document.addEventListener("visibilitychange", handle);

        return () => document.removeEventListener("visibilitychange", handle);
    }, [states.basketInfo?.id])

    return statesprovider
}

export const useRemoveBasket = () => {
    const {setStates} = useBasket()
    
    return useCallback(() => {
        setStates(prev => ({
            ...prev,
            basketInfo: {
                id: 0
            }
        }))
    },[])
}

interface BasketContextProps {
    states: BasketStates;
    setStates: React.Dispatch<React.SetStateAction<BasketStates>>;
    data?: BasketPropType;
    items: BasketItemPropType[];
    loading: boolean;
    refetch: () => Promise<any>;
}

const BasketContext = createContext<BasketContextProps>(null as any)

export const useBasket = () => {
    return useContext(BasketContext)
}

const BasketProvider: React.FunctionComponent = ({children}) => {
    const [states, setStates] = useCreateBasket()
    const requestPickBasket = useAuthAPI('/api/Basket/pick')
    const [load, request] = useLazyQuery<{ screen: BasketPropType }>(BasketScreenQuery, { 
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if(states.basketInfo?.id) {
            load({
                variables: { id: states.basketInfo?.id }
            })
            loadBasketItems({
				variables: {
					basketID: states.basketInfo?.id
				}
			})
        }
    }, [states.basketInfo?.id])

    const [loadBasketItems, requestBasketItems] = useLazyQuery<BasketItemsGridQueryType>(BasketItemsGridQuery, {
        // notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        const handle = function() {
            if (document.visibilityState === 'visible') {
                if(requestBasketItems.refetch) {
                    requestBasketItems.refetch({
                        variables: {
                            basketID: states.basketInfo?.id
                        }
                    })
                }
            } else {
                // console.log('lost focus');
            }
        }
        document.addEventListener("visibilitychange", handle);

        return () => document.removeEventListener("visibilitychange", handle);
    }, [states.basketInfo?.id, requestBasketItems.refetch])

    const items = useMemo(() => requestBasketItems.data?.grid || [], [requestBasketItems.data])

    useEffect(() => {
        if(states.hasOverlapBasket && (items).length === 0 && !requestBasketItems.loading) {
            requestPickBasket.call({
                data: {
                    "original": {},
                    "new": {
                        "sessionID": states.anonymousBasketInfo?.sessionID,
                        "pickBasketID": states.anonymousBasketInfo?.id,
                        "discardedBasketID": states.basketInfo?.id,
                    }
                }
            })
            .then((response) => {
                if (response.data.success) {
                    setStates(prev => ({
                        ...prev,
                        basketInfo: {...prev.anonymousBasketInfo!},
                        hasOverlapBasket: false,
                    }))
                    cookie.unset('anonymousBasket', {
                        domain: branding.token.domain
                    })
                } else {
                    cookie.unset('anonymousBasket', {
                        domain: branding.token.domain
                    })
                    window.location.reload()
                }
            })
            .catch(() => {
                cookie.unset('anonymousBasket', {
                    domain: branding.token.domain
                })
                window.location.reload()
            });
        }
    }, [request.data, items])

    const refetch = useCallback(() => {
        if(request?.refetch) {
            return request.refetch().then(() => {
                if(requestBasketItems?.refetch) {
                    return requestBasketItems.refetch()
                }
            })
        }
        
        return Promise.resolve()
    }, [request?.refetch, requestBasketItems?.refetch])

    return (
        <BasketContext.Provider value={{
            states,
            setStates,
            items,
            data: request.data?.screen,
            loading: request.loading || requestBasketItems.loading || states.loading,
            refetch
        }}>
            {children}
        </BasketContext.Provider>
    )
}

export default BasketProvider