import { gql } from "@apollo/client";

export type BasketPropType = {
    ID: number;
    CurrencyID: number;
    Currency: {
        Value?: {
            Code: string;
        }
    }
}

export const BasketScreenQuery = gql`
  query($id: Int!) {
    screen: Basket(id: $id) {
        ID
        CurrencyID
        Currency {
            Value {
                Code
            }
        }
    }
}`
export type BasketItemPropType = {
    ID: number;
    ProductID: number;
    Quantity: number;
    UnitPrice: number;
    VAT: number;
    POA: boolean;
    Product: {
        Value?: {
            ProductCode: string;
            DisplayName: string;
            ProductImages: {
                ImageUrl: string;
            }[]
            ProductTypeID: number;
            NoFedEx: boolean;
            MaxFedExVolume: number;
            Cores: {
                ProductLineID: number;
            }[]
            ProductMinimumOrders: {
                Quantity: number;
                QuantityMultiple: number;
                PricingRegionID: number;
            }[]
        }
    }
}

export type BasketItemsGridQueryType = {
    grid: BasketItemPropType[]
}
export const BasketItemsGridQuery = gql`
  query($basketID: Int!) {
    grid: BasketItems(basketID: $basketID) {
        ID
        ProductID
        Quantity
        UnitPrice
        VAT
        POA
        Product {
            Value {
                ProductCode
                DisplayName
                ProductImages {
                    ImageUrl 
                }
                ProductTypeID
                NoFedEx
                MaxFedExVolume
                Cores {
                    ProductLineID
                }
                ProductMinimumOrders {
                    Quantity
                    QuantityMultiple
                    PricingRegionID
                }
            }
        }
    }
}`