import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ServicesConfig from '@whitecobalt/tungsten/ServicesConfig';
import '@styles/bootstrap.min.css';
import '@styles/fontawesome5/css/fontawesome.css';
import '@styles/wc-strip.css';
import '@styles/main.scss';
import Application from '@app/index';


ReactDOM.render(
	<React.StrictMode>
		<ServicesConfig>
			<BrowserRouter>
				<Application />
			</BrowserRouter>
		</ServicesConfig>
	</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
