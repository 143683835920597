import branding from "@config/branding"
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import cookie from "@whitecobalt/tungsten/esm/common/utils/cookie"
import ModalConfirm from "@whitecobalt/tungsten/esm/components/Modal/Confirm"
import { SessionActionType, useSession } from "@whitecobalt/tungsten/esm/components/Session"
import Toaster from "@whitecobalt/tungsten/esm/components/Toaster"
import { useHistory } from "react-router-dom"

export const useImpersonation = () => {
    const requestImpersonate = useAuthAPI('/api/Impersonation')
    const history = useHistory()
    const [{token}, dispatch] = useSession()

    return {
        ...requestImpersonate,
        call: (payload: {
            userID: number;
            organisationID: number,
            applicationAreaID: number;
          }) => {
            const toaster = Toaster("Impersonating user")
            return requestImpersonate.call({
                data: {
                    ...payload
                }
            }).then((response) => {
                if(response.data.success) {
                    toaster.success("Successfully impersonated the user")
                    cookie.unset(branding.token.name)
                    cookie.unset(branding.token.name+"OG")
                    localStorage.removeItem('expireTimestamp')
                    window.location.assign(`${window.location.origin}/auth/impersonation/${token}/${response.data.token}/${btoa(window.location.href)}`)
                }
            })
            .catch(() => {
                toaster.error()
            })
        }
    }
}

export const useRevokeImpersonation = () => {
    const [, dispatch] = useSession()
    
    return () => {
        ModalConfirm({
            title: 'Impersonation Session',
            titleIcon: 'fa fa-user-secret',
            className: 'halspan-modal',
            body: "Would you like to end this Impersonation Session?"
        })
        .then((confirm) => {
            if(confirm) {
                Toaster.success("Successfully teminated session")
                const originalToken = cookie.get(branding.token.name+"OG")
                let urlOG = cookie.get("urlOG") || ""

                if(urlOG) {
                    urlOG = atob(urlOG)
                }

                if(urlOG?.includes(window.location.origin)) {
                    cookie.set(branding.token.name, originalToken, { expireday: branding.token.expireday })
                    dispatch({
                        type: SessionActionType.SET_TOKEN,
                        payload: originalToken,
                    });
                } else {
                    cookie.unset(branding.token.name)
                }
                
                cookie.unset(branding.token.name+"OG")
                cookie.unset("urlOG")
                
                window.location.assign(urlOG)
                
            }
        }) 
    }
}