import React, { useLayoutEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import classNames from 'classnames'
import Collapse from '@whitecobalt/tungsten/Collapse'
import { useSession } from '@whitecobalt/tungsten/Session'
import Icon from '@whitecobalt/tungsten/Icon'
import halspanLogo from '@images/halspan-logo.png'
import { asideNavigationPath } from '@utils/variables'
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI'
import branding from '@config/branding'
import { useBasket } from '@components/BasketProvider'
import cookie from '@whitecobalt/tungsten/esm/common/utils/cookie'
import { useRevokeImpersonation } from '@hooks/useImpersonation'
import Spinner from 'react-bootstrap/Spinner'

const Header: React.FunctionComponent<any> = () => {
    const [{user, token}] = useSession()
    const {items, states, loading} = useBasket()
    const hasAuthenthication = !!token
    const search = useRef<HTMLDivElement>(null)
    const requestNavigation = useAuthAPI('/api/NavigationMenu/2', {
        method: 'GET'
    })

    const handleRevokeImpersonation = useRevokeImpersonation()

    useLayoutEffect(() => {
        requestNavigation.call()
    }, [])

    const menu = requestNavigation.response?.menu || []

    const handleToggleSearch = (e: any) => {
        e.preventDefault()
        if(['A', 'I', 'DIV'].includes(e.target.nodeName)) {
            search.current?.classList.toggle('open')

            if(search.current?.classList.contains('open'))
                search.current?.querySelector('input')?.focus()
        }
    }

    const basketCount = items.reduce((result, each) => {
        return result + each?.Quantity
    }, 0)
  
    return (
      <>
        <div ref={search} onClick={handleToggleSearch} id="search-overlay">
            <form role="search" action="https://halspan.com/" method="get">
                <input placeholder="Search..." autoFocus type="text" name="s" title="Search" />
            </form>
        </div>
        <Collapse eventKey="nav-mobile" transition="fast" className="nav-menu-mobile">
            <ul>
                {menu.map((nav: any, index: number) => {
                    return nav.items.length > 0 ? (
                        <li key={index} className="has-sub">
                            <a href={nav.url}>
                                {nav.title}
                            </a>
                            <ul className="nav-menu-sub">
                                {nav.items.map((sub: any, subindex: number) => {
                                    return <li key={subindex}><a href={sub.url}>{sub.title}</a></li>
                                })}
                            </ul>
                        </li>
                    ) : (
                        <li key={index}>
                            <a href={nav.url}>{nav.title}</a>
                        </li>
                    )
                })}
                <li className="contactus"><a href="https://halspan.com/contact-us/"><span>Contact Us</span></a></li>
            </ul>
        </Collapse>
        <div className="halspan-navbar container-fluid">
            <div className="row">
                <nav className="navbar fixed-top">
                <div className="col-md-3 pr-0">
                    <div className="halspan-logo">
                    <a href="https://halspan.com/">
                        <img width={1000} height={246} src={halspanLogo} className="attachment-full size-full" loading="lazy" />
                    </a>
                    </div>
                </div>
                <div className="col-md-9 pl-0 pr-sm-0"> 
                    {!states?.hasOverlapBasket && (
                        <div className="float-right">
                            <Link to="/store/basket" className="nav-search-button mr-sm-3">
                                <div className="nav-search-icon position-relative">
                                    <i className="fa fa-shopping-cart" />
                                    <span className="basket-count">{loading ? <Spinner animation="border" size="sm" style={{ width: '0.75rem', height: '0.75rem'}}/> : basketCount}</span>
                                </div>
                            </Link>
                        </div>  
                    )}        
                    {!hasAuthenthication ? (
                        <div className="float-right">
                            <Link to="/auth/login" className="nav-search-button mr-2">
                                <div className="nav-search-icon">
                                    <i className="fas fa-user" />
                                </div>
                            </Link>
                        </div>
                    ) : (
                        <div className="float-right">
                            <Dropdown>
                                <Dropdown.Toggle as="a" className="nav-search-button mr-2">
                                    <div className="nav-search-icon">
                                        <i className="fas fa-user" />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {user?.applicationAreaIDs.includes(2) && 
                                        <Dropdown.Item exact as={NavLink} to={asideNavigationPath}>
                                            Halspan Verified Management Portal
                                        </Dropdown.Item>
                                    }
                                    {user?.applicationAreaIDs.includes(3) && 
                                        <Dropdown.Item href={branding.services.env === 'production' ? "https://tracker.halspan.com/" : branding.services.env === 'staging' ? "https://tracker-staging.halspan-test.com" : "https://tracker.halspan-test.com/" }>
                                            Halspan Tracker Asset Management System
                                        </Dropdown.Item>
                                    }
                                    <Dropdown.Item exact as={NavLink} to={`${asideNavigationPath}/my-profile`}>
                                        <Icon name="user-cog" className="mr-3"/> My Profile
                                    </Dropdown.Item>
                                    {cookie.get(branding.token.name+"OG") ? (
                                        <Dropdown.Item onClick={handleRevokeImpersonation}>
                                            <Icon name="user-secret" className="mr-3"/> Log out
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item as={NavLink} to="/auth/logout">
                                            <Icon name="sign-out-alt" className="mr-3"/> Log out
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}                           
                    <div className="float-right">
                        <a href="https://halspan.com#search" onClick={handleToggleSearch} className={classNames("nav-search-button mr-2")}>
                            <div className="nav-search-icon">
                                <i className="fas fa-search" />
                            </div>
                        </a>
                    </div>                                          
                    <div className="float-right mr-sm-2">
                    <a href="https://halspan.com/contact-us/" className="nav-contact-button">Contact</a>
                    <Collapse.Target as="div" eventKey="nav-mobile" className="nav-menu-mobile-toggle">
                        <i className="fas fa-bars" />
                    </Collapse.Target>
                    <Collapse.Target as="div" eventKey="nav-mobile" className="nav-menu-mobile-toggle-expanded">
                        <i className="fas fa-times" />
                    </Collapse.Target>
                    </div>
                    <div className="float-right">
                        <ul className="nav-menu">
                            {menu.map((nav: any, index: number) => {
                                return nav.items.length > 0 ? (
                                    <li key={index}>
                                        <a href={nav.url}>
                                            {nav.title}
                                        </a>
                                        <ul>
                                            {nav.items.map((sub: any, subindex: number) => {
                                                return <li key={subindex}><a href={sub.url}>{sub.title}</a></li>
                                            })}
                                        </ul>
                                    </li>
                                ) : (
                                    <li key={index}>
                                        <a href={nav.url}>{nav.title}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                </nav>
            </div>
        </div>
        <div className="halspan-navbar-ember container-fluid">
            <div className="col-md-12 wc-header-blank">
                {/*<img src="https://halspan.websitedesignerscotland.co.uk/wp-content/uploads/2020/12/hero.jpg" class="img-fluid" />*/}
            </div>
        </div>
      </>
    )
}

export default Header
