import { LazyQueryResult, useLazyQuery } from '@apollo/client'
import { AccreditationGridQuery, AccreditationPropType } from '@services/query/grid/AccreditationsQuery'
import { organisationIndex } from '@utils/variables'
import { useSession } from '@whitecobalt/tungsten/esm/components/Session'
import React, { createContext, useContext, useEffect } from 'react'

const AccreditationContext = createContext<LazyQueryResult<{
    grid?: {
        items: AccreditationPropType[];
    } | undefined;
}, Record<string, any>>>(null as any)

export const useAccreditation = () => {
    return useContext(AccreditationContext)
}

export const useHasAccreditation = () => {
    const request = useAccreditation()
    return {
        show: (request.data?.grid?.items || []).length > 0,
        loading: request.loading
    }
}

interface AccreditationProviderProps {
    
}

const AccreditationProvider: React.FunctionComponent<AccreditationProviderProps> = ({children}) => {
    const [{user}] = useSession()
    const organisation = user?.organisations[organisationIndex]
    const [loadAccreditations, requestAccreditations] = useLazyQuery<{ grid?: { items: AccreditationPropType[]}}>(AccreditationGridQuery, {
        fetchPolicy: 'cache-first'
    })
    useEffect(() => {
        if((!!user && organisation) || (!!requestAccreditations.data && !user)) {
            loadAccreditations({
                variables: {
                    // take: 1,
                    where: {
                        OrganisationID: {
                            equals: organisation?.id
                        },
                        // Verified: {
                        //     equals: true
                        // }
                    },
                    order: {
                        CertifyingBodyID: "ASC"
                    }
                }
            })
        }
    }, [user?.id, organisation?.id])

    return (
        <AccreditationContext.Provider value={requestAccreditations}>
            {children}
        </AccreditationContext.Provider>
    )
}

export default AccreditationProvider
