import { lazy } from 'react'

export const Login = lazy(() => import('./Auth/Login'))

export const Impersonation = lazy(() => import('./Auth/Impersonation'))

export const Registration = lazy(() => import('./Auth/Registration'))

export const Logout = lazy(() => import('./Auth/Logout'))

export const ConfirmEmail = lazy(() => import('./Auth/ConfirmEmail'))

export const ForgotPassword = lazy(() => import('./Auth/ForgotPassword'))

export const PasswordReset = lazy(() => import('./Auth/PasswordReset'))

export const MFA = lazy(() => import('./Auth/MFA'))

export const AcceptProject = lazy(() => import('./AcceptProject'))

export const BecomeAVerifiedPartner = lazy(() => import('./BecomeAVerifiedPartner'))

export const BuildForgeView = lazy(() => import('./BuildForgeView'))

export const DoorConfigurator = lazy(() => import('./DoorConfigurator'))

export const DoorSystemSelector = lazy(() => import('./DoorSystemSelector'))

export const Dashboard = lazy(() => import('./Dashboard'))

export const UsersArea = lazy(() => import('./UsersArea'))

export const ProductSelector = lazy(() => import('./ProductSelector'))

export const Ecommerce = lazy(() => import('./Ecommerce'))

export const TechnicalRequestInquiry = lazy(() => import('./TechnicalRequestInquiry'))

export const InspectionServicesEnquiry = lazy(() => import('./InspectionServicesEnquiry'))

export const ConfirmCompanyDetails = lazy(() => import('./ConfirmCompanyDetails'))

export const ContactUs = lazy(() => import('./ContactUs'))

export const ThirdPartyScopeData = lazy(()=> import('./ThirdPartyScopeData'))