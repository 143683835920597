import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLinearToken } from "@whitecobalt/tungsten/hooks/useLinearToken";
import { useReceiveUser } from '@hooks/useReceiveUser';
import { useUpdateApplication } from '@whitecobalt/tungsten/hooks/useUpdateApplication';
import Routes from './Routes';
import Header from './Header';
import Footer from './Footer';
import BasketProvider from '@components/BasketProvider';
import AccreditationProvider from '@components/AccreditationProvider';
import OrganisationCurrenciesProvider from '@components/OrganisationCurrenciesProvider';
import TechRequestProvider from '@components/TechRequestfProvider';

const Application = () => {
	const location = useLocation()

	useLinearToken()
	useReceiveUser()
	useUpdateApplication(5)

	useEffect(() => {
		if(document?.documentElement && (location.state as any)?.preventScrollTop !== true) {
			document.documentElement.scrollTop = 0
		}
	}, [location.pathname])

	const isForgeView = /^\/build-forge-view/.test(location.pathname)
	// const isUsersArea = /^\/users/.test(location.pathname)
	return (
		<AccreditationProvider>
			<TechRequestProvider>
				<OrganisationCurrenciesProvider>
					<BasketProvider>
						{isForgeView || <Header />}
						<Routes />
						{(isForgeView) || <Footer />}
					</BasketProvider>
				</OrganisationCurrenciesProvider>
			</TechRequestProvider>
		</AccreditationProvider>
	);
}

export default Application;