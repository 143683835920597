import { gql } from "@apollo/client";

export type AccreditationPropType = {
    ID: number;
    OrganisationID: number;
    CertifyingBodyID: number;
    CertifyingBody?: {
        Value?: {
            Description: string;
        }
    }
    CertificateNumber: string;
    Verified: boolean;
    FileKey: string;
}

export const AccreditationGridQuery = gql`
query(
    $skip: Int,
    $take: Int,
    $where: AccreditationFilterInput,
    $order: [AccreditationSortInput!]
) {
  grid: Accreditations(
      skip: $skip,
      take: $take,
      where: $where,
      order: $order
  ) { 
      items {
        ID
        OrganisationID
        CertifyingBodyID
        CertifyingBody {
            Value {
                Description
            }
        }
        CertificateNumber
        Verified
        FileKey
      }
    }
  }`
  