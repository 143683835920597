import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Loader from '@whitecobalt/tungsten/Loader'
import halspanLogo from '@images/halspan-logo.png'
import './index.scss'

interface HalspanLoaderProps {
    
}

const HalspanLoader: React.FunctionComponent<HalspanLoaderProps> = ({ children }) => {
    return (
        <Loader fixed active hasSpinner={false} noTransition>
			<div className="halspan-loader-content">
				<img width="300" src={halspanLogo} alt="halspan" />
				<Spinner animation="border" />
				{children}
			</div>
		</Loader>
    )
}

export default HalspanLoader
