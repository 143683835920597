import { useEffect } from "react"
import { SessionActionType, useSession } from "@whitecobalt/tungsten/Session"
import { useAuthAPI } from "@whitecobalt/tungsten/hooks/useAuthAPI"
import { UserProps } from "@types"

export const useReceiveUser = () => {
    const [{initial, token, received_once}, dispatch] = useSession()
    const requestUser = useAuthAPI('/api/Me', { method: 'GET' })
    const requestImage = useAuthAPI('/api/FileURL')

    useEffect(() => {
        if(!initial) {
            if(token) {
                if(!received_once) {
                    const controller = new AbortController()
                    requestUser.call({
                        signal: controller.signal
                    }).then(async response => {
                        const payload: UserProps = response.data || null
    
                        if(payload?.organisationIDs && payload?.organisationIDs.length > 0 && payload?.organisations[0]?.companyLogo) {
                            payload.organisations = await Promise.all(payload.organisations.filter((org) => !!org).map(async (org) => {
                                const response = await requestImage.call({
                                    data: {
                                        fileKey: org?.companyLogo,
                                        forceDownload: true,
                                        thumbnail: true
                                    }
                                })
                                return {
                                    ...org,
                                    companyLogoURL: response.data.url
                                }
                            }))
                        }
                        
                        dispatch({ 
                            type: SessionActionType.SET_USER,
                            payload
                        })
                        
                    })
                    .catch(() => {
                        dispatch({ 
                            type: SessionActionType.SET_TOKEN,
                            payload: null
                        })
                    })

                    return () => controller.abort()
                }
            } else {
                dispatch({ 
                    type: SessionActionType.SET_USER,
                    payload: null
                })
            }
        }
    }, [token, initial])
}