import { gql } from "@apollo/client";

export type OrganisationCurrenciesPropTypeQuery = {
    screen: {
        items: {
            ID: number;
            CurrencyID: number;
            AllowTransaction: boolean;
        }[]
    }
}

export const OrganisationCurrenciesScreenQuery = gql`
  query($organisationID: Int!) {
    screen: OrganisationCurrencies (OrganisationID: $organisationID) {
        items {
            ID
            CurrencyID
            AllowTransaction
        }
    }
}`