import React from 'react'
import bmtrada from '@images/accreditations/bmtrada.jpg'
import certifire from '@images/accreditations/certifire.jpg'
import fsc from '@images/accreditations/fsc.jpg'
import ifc from '@images/accreditations/ifc.jpg'
import intertek from '@images/accreditations/intertek.png'
import halspanLogoWithStrapline from '@images/halspan-logo-with-strapline.svg'
import branding from '@config/branding'

const Footer: React.FunctionComponent = (props) => {
    return (
        <footer className="halspan-footer">
          {/* <div className="footer-logos">
            <div className="container">
              <div className="col-md-12">
                <ul className="logos">
                  <li>
                    <img src={bmtrada} />
                  </li>
                  <li>
                    <img src={certifire} />
                  </li>
                  <li>
                    <img src={ifc} />
                  </li>
                  <li>
                    <img src={intertek} />
                  </li>
                  <li>
                    <img src={fsc} />
                  </li>
                </ul>
                <div className="clearfix" />
              </div>
            </div>
          </div> */}
          <div className="footer-main">
            <div className="footer-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <div className="footer-logo">
                      <img src={halspanLogoWithStrapline} className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-3">&nbsp;</div>
                  <div className="mt-4 d-md-none d-lg-none">&nbsp;</div>
                  <div className="col-md-4">
                    <div className="float-left">
                      <a href="https://www.linkedin.com/company/halspan-limited/about/" target="_blank" className="social-icon">
                        <i className="fab fa-linkedin" />
                      </a>
                    </div>
                    <div className="float-left">
                      <a href="https://www.youtube.com/channel/UC2vWOiSWC7CQNJahon3m_fQ" target="_blank" className="social-icon">
                        <i className="fab fa-youtube" />
                      </a>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="mb-4 d-md-none d-lg-none">&nbsp;</div>
                </div>
                <div className="row pad25">
                  <div className="col-md-4">
                    <p>
                      <strong>Head Office</strong><br />
                  Regent House, Regent Centre,<br />
                  Linlithgow, West Lothian, Scotland, UK,<br />
                  EH49 7HU
                </p>
                    <p>
                      <strong>+44 (0)3300 563836</strong><br />
                      <a href="mailto:info@halspan.com">info@halspan.com</a>
                    </p>
                    <br />
                  </div>
                  <div className="mb-4 d-md-none d-lg-none">&nbsp;</div>
                  <div className="col-md-4">
                    <p>
                      <strong>Other office locations:</strong>
                      <br /><br />
                      <a href="https://halspan.com/contact-us/our-locations#stansted">&gt; Stansted UK</a><br />
                      <a href="https://halspan.com/contact-us/our-locations#dubai">&gt; Dubai, UAE</a><br />
                      <a href="https://halspan.com/contact-us/our-locations#india">&gt; Gurugram, India</a><br />
                    </p>
                  </div>
                  <div className="mb-4 d-md-none d-lg-none">&nbsp;</div>
                  <div className="col-md-4">
                    <ul className="other-links">
                      <li>
                        <a href="https://halspan.com/resources/">
                          <span className="elementor-icon-list-icon">
                            <i aria-hidden="true" className="fas fa-chevron-right" />
                          </span>
                          <span>Resources</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://halspan.com/contact-us/">
                          <span className="elementor-icon-list-icon">
                            <i aria-hidden="true" className="fas fa-chevron-right" />
                          </span>
                          <span className="elementor-icon-list-text">Contact Us</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <p style={{ "color": "#ffffff" }}>
                      © Halspan Limited. All rights reserved.<br />
                  Company registered in Scotland number SC194458<br />
                  Registered office: Halspan Limited, Muirhouses, Bo’Ness, West Lothian, EH51 9SS, UK
                </p>
                  </div>
                  <div className="col-md-4">&nbsp;</div>
                  <div className="col-md-4">
                    <p>
                      <span style={{ "color": "#ffffff" }}>
                        <a style={{ "color": "#ffffff" }} href="https://halspan.com/terms-conditions/">Terms &amp; Conditions</a>&nbsp;|&nbsp;
                    <a style={{ "color": "#ffffff" }} href="https://halspan.com/privacy-policy/">Privacy Policy</a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(branding.services.env.toLocaleLowerCase() === "staging" || branding.services.env.toLocaleLowerCase() === "uat") && (
            <div className='bg-danger p-1 text-center position-sticky' style={{bottom:"0"}}>
              <h3 className='text-white'>TEST SITE</h3>
            </div>
          )}
        </footer>
    )
}

export default Footer
