import { LazyQueryResult, useLazyQuery } from '@apollo/client'
import { TechRequestFormsGridQuery, TechRequestFormPropType } from '@services/query/grid/TechRequestFormsQuery'
import { organisationIndex } from '@utils/variables'
import { useSession } from '@whitecobalt/tungsten/esm/components/Session'
import React, { createContext, useContext, useEffect } from 'react'

const TechRequestContext = createContext<LazyQueryResult<{
    grid?: {
        items: TechRequestFormPropType[];
    } | undefined;
}, Record<string, any>>>(null as any)

export const useTechRequest = () => {
    return useContext(TechRequestContext)
}

export const useHasTechRequest = () => {
    const request = useTechRequest()
    return {
        show: (request.data?.grid?.items || []).length > 0,
        loading: request.loading
    }
}

interface TechRequestProviderProps {
    
}

const TechRequestProvider: React.FunctionComponent<TechRequestProviderProps> = ({children}) => {
    const [{user}] = useSession()
    const organisation = user?.organisations[organisationIndex]
    const [loadTechRequests, requestTechRequests] = useLazyQuery<{ grid?: { items: TechRequestFormPropType[]}}>(TechRequestFormsGridQuery, {
        fetchPolicy: 'no-cache'
    })
    useEffect(() => {
        if((!!user && organisation) || (!!requestTechRequests.data && !user)) {
            loadTechRequests({
                variables: {
                    organisationID: organisation?.id,
                    take: 1
                }
            })
        }
    }, [user?.id, organisation?.id])

    return (
        <TechRequestContext.Provider value={requestTechRequests}>
            {children}
        </TechRequestContext.Provider>
    )
}

export default TechRequestProvider
