import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useOrganisation } from '@hooks/useOrganisation'
import { OrganisationCurrenciesPropTypeQuery, OrganisationCurrenciesScreenQuery } from '@services/query/OrganisationCurrenciesQuery'

const OrganisationCurrenciesContext = createContext<{ data: number[], refetch: () => void }>(null as any)

export const useOrganisationCurrencies = () => useContext(OrganisationCurrenciesContext)

interface OrganisationCurrenciesProviderProps {
    
}

const OrganisationCurrenciesProvider: React.FunctionComponent<OrganisationCurrenciesProviderProps> = ({children}) => {
    const organisation = useOrganisation()
    const [load, request] = useLazyQuery<OrganisationCurrenciesPropTypeQuery>(OrganisationCurrenciesScreenQuery, {
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        if(organisation || (!!request.data && !organisation)) {
            load({
                variables: {
                    organisationID: organisation?.id
                }
            })
        }

    }, [organisation])

    const value = useMemo(() => {
        return { data: request.data?.screen.items.filter((each) => each.AllowTransaction).map((each) => each.CurrencyID) || [], refetch: request.refetch as any }
    }, [request.data])

    return (
        <OrganisationCurrenciesContext.Provider value={value}>
           {children} 
        </OrganisationCurrenciesContext.Provider>
    )
}

export default OrganisationCurrenciesProvider
