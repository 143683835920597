export const asideNavigationPath =  '/users'

if(!localStorage.getItem('ogIndex')) {

    localStorage.setItem('ogIndex', '0')

}

export let organisationIndex = parseInt(localStorage.getItem('ogIndex') || '0')

export const changeOrganisationIndex = (index: number) => {
    organisationIndex = index
    localStorage.setItem('ogIndex', `${index}`)
}

export const tempFileManagerData = [
    {
        ID: 1,
        Title: 'Folder 1',
        IsAssignable : true,
        DateCreated: '11/12/2022 11:11:11 PM',
        Documents: [
            {
                ID: 1,
                Title: 'File 1',
                FileKey: 'uploads/2022-05-22-56576442.png',
                DateCreated: '11/12/2022 11:11:11 PM',
            },
            {
                ID: 1,
                Title: 'File 2',
                FileKey: 'uploads/2022-05-22-56576442.png',
                DateCreated: '11/12/2022 11:11:11 PM',
            },
        ],
        DocumentGroups: [
            {
                ID: 2,
                Title: 'Sub Folder 1',
                DateCreated: '11/12/2022 11:11:11 PM',
                Documents: [
                    {
                        ID: 1,
                        Title: 'File 1',
                        FileKey: 'uploads/2022-05-22-56576442.png',
                        DateCreated: '11/12/2022 11:11:11 PM',
                    },
                    {
                        ID: 1,
                        Title: 'File 2',
                        FileKey: 'uploads/2022-05-22-56576442.png',
                        DateCreated: '11/12/2022 11:11:11 PM',
                    },
                ],
                DocumentGroups: [
                    {
                        ID: 4,
                        Title: 'Sub Sub Folder 1',
                        DateCreated: '11/12/2022 11:11:11 PM',
                        Documents: [
                            {
                                ID: 1,
                                Title: 'File 1',
                                FileKey: 'uploads/2022-05-22-56576442.png',
                                DateCreated: '11/12/2022 11:11:11 PM',
                            },
                            {
                                ID: 1,
                                Title: 'File 2',
                                FileKey: 'uploads/2022-05-22-56576442.png',
                                DateCreated: '11/12/2022 11:11:11 PM',
                            },
                        ]
                    }
                ]
            },
            {
                ID: 3,
                Title: 'Sub Folder 2',
                DateCreated: '11/12/2022 11:11:11 PM',
                IsAssignable : true,
                Documents: [
                    {
                        ID: 1,
                        Title: 'File 1',
                        FileKey: 'uploads/2022-05-22-56576442.png',
                        DateCreated: '11/12/2022 11:11:11 PM',
                    },
                    {
                        ID: 1,
                        Title: 'File 2',
                        FileKey: 'uploads/2022-05-22-56576442.png',
                        DateCreated: '11/12/2022 11:11:11 PM',
                    },
                ],
            }
        ]
    },
    {
        ID: 5,
        Title: 'Folder 2',
        DateCreated: '11/12/2022 11:11:11 PM',
        IsAssignable : true,
        Documents: [
            {
                ID: 1,
                Title: 'File 1',
                FileKey: 'uploads/2022-05-22-56576442.png',
                DateCreated: '11/12/2022 11:11:11 PM',
            },
            {
                ID: 1,
                Title: 'File 2',
                FileKey: 'uploads/2022-05-22-56576442.png',
                DateCreated: '11/12/2022 11:11:11 PM',
            },
        ],
    }
]