import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import HalspanLoader from "@components/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import { 
    Login, 
    Impersonation,
    Registration, 
    Logout, 
    ConfirmEmail,
    ForgotPassword,
    PasswordReset,
    AcceptProject,
    DoorSystemSelector,
    DoorConfigurator,
    BuildForgeView,
    BecomeAVerifiedPartner,
    UsersArea,
    Dashboard,
    ProductSelector,
    Ecommerce,
    MFA,
    TechnicalRequestInquiry,
    InspectionServicesEnquiry,
    ConfirmCompanyDetails,
    ContactUs,
    ThirdPartyScopeData
} from "./Pages";
import branding from "@config/branding";

const Routes = () => {
    const [{user, redirect, loading }, dispatch] = useSession();
	const hasAuthenthication = !!user

	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname + props.location.search
			})
		}
    }

    if(loading) return (
		<HalspanLoader/>
    )
    
	return (
        <main className="halspan-content">
            <Suspense fallback={(
                   <HalspanLoader />
                )}>
                <Switch>
                    <Redirect exact from="/" to={`${hasAuthenthication ? "/users/my-projects" : "/door-system-selector"}`} />
                    <Redirect exact from="/accreditation-hub" to="/users/accreditation-hub" />
                    <Route path="/door-system-selector"component={DoorSystemSelector}exact/>
                    <Route path="/third-party-scope-data/:id"component={ThirdPartyScopeData}exact/>
                    <Route path="/become-a-verified-partner" component={BecomeAVerifiedPartner} exact/>
                    <Route path="/technical-enquiry" component={TechnicalRequestInquiry} exact/>
                    <Route path="/inspection-services-enquiry" component={InspectionServicesEnquiry} exact/>
                    <Route path="/contact-us" component={ContactUs} exact/>
                    <Route path="/store" component={Ecommerce} />
                    <Route path="/auth/confirm-email" component={ConfirmEmail} exact />
                    <Route path="/auth/password-reset" component={PasswordReset} exact />
                    <Route path="/halspan-exemplar-doors/:id" component={DoorConfigurator} exact />
                    <Route
                        path="/confirm-company-details"
                        component={ConfirmCompanyDetails}
                        exact
                        />
                    <Route path="/auth/impersonation/:originalToken/:impersonationToken/:url" component={Impersonation}/>
                    <ConditionalRoute
                        condition={hasAuthenthication}
                        redirectTo="/auth/login"
                        path="/product-selector"
                        component={ProductSelector}
                        onRedirect={handleRedirect}
                        exact
                        />
                    <ConditionalRoute
                        condition={!hasAuthenthication} 
                        redirectTo="/auth/login"
                        path="/auth/mfa"
                        component={MFA}
                        exact/>
                    <ConditionalRoute
                        condition={hasAuthenthication}
                        redirectTo="/auth/login"
                        path="/auth/logout"
                        component={Logout}
                        exact
                        />
                    <ConditionalRoute
                        condition={!hasAuthenthication}
                        redirectTo={redirect || branding.landingPath}
                        path="/auth/login"
                        component={Login}
                        exact
                        />
                    <ConditionalRoute
                        condition={!hasAuthenthication}
                        redirectTo={redirect || branding.landingPath}
                        path="/auth/create-account"
                        component={Registration}
                        exact/>
                    <ConditionalRoute
                        condition={!hasAuthenthication}
                        redirectTo={redirect || branding.landingPath}
                        path="/auth/forgot-password"
                        component={ForgotPassword}
                        exact/>
                    <ConditionalRoute
                        condition={hasAuthenthication}
                        redirectTo="/auth/login"
                        path="/build-forge-view/:id"
                        component={BuildForgeView}
                        onRedirect={handleRedirect}
                        exact/>
                    <ConditionalRoute
                        condition={hasAuthenthication}
                        redirectTo="/auth/login"
                        path="/projects/accept-invite"
                        component={AcceptProject}
                        onRedirect={handleRedirect}
                        exact
                        />
                    <ConditionalRoute
                        condition={hasAuthenthication}
                        redirectTo="/auth/login"
                        path="/users"
                        component={Dashboard}
                        onRedirect={handleRedirect}
                        exact
                        />
                    <ConditionalRoute
                        condition={hasAuthenthication}
                        redirectTo="/auth/login"
                        path="/users"
                        component={UsersArea}
                        onRedirect={handleRedirect}
                        />
                    
                    <Route path="*" render={() => { 
                        window.location.assign('http://halspan.com/404') 
                        return null
                    }}/>
                </Switch>
            </Suspense>
        </main>
	);
}

export default Routes